import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import { campaignPropType } from '~/propTypes';

import mutation from './_EditCampaign.gql';
import CampaignForm from './CampaignForm';

export default function CampaignEdit({ campaignData }) {
  const { campaign } = useParams();

  const [action] = useMutation(mutation);
  const history = useHistory();

  const {
    name,
    code,
    site,
    duration,
    limit,
    expireDate,
    products,
    firstPurchaseOnProductOnly,
    type,
    price,
    presentation,
  } = campaignData.getCampaign;

  return (
    <>
      <Breadcrumb name="Redigera" />
      <CampaignForm
        initialValues={{
          name,
          code,
          type,
          price: Math.round(price / 100),
          site,
          firstPurchaseOnProductOnly: firstPurchaseOnProductOnly || false,
          expireDate: expireDate || '',
          limit: limit || '',
          duration: duration || '',
          products,
          presentation: {
            headline: presentation.headline || '',
            infoText: presentation.infoText || '',
            buttonText: presentation.buttonText || '',
            terms: presentation.terms || '',
          },
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const { price, expireDate, duration, limit, products, ...rest } =
            values;
          const modifiedValues = {
            ...rest,
            duration: duration === '' ? null : Number(duration),
            expireDate: expireDate === '' ? null : expireDate,
            limit: limit === '' ? null : Number(limit),
            price: Number(price) * 100,
          };

          await action({
            variables: {
              id: campaign,
              input: modifiedValues,
            },
          });
          setSubmitting(false);
          history.push(`/campaigns/${campaign}`);
        }}
      />
    </>
  );
}

CampaignEdit.propTypes = {
  campaignData: PropTypes.shape({
    getCampaign: campaignPropType,
  }).isRequired,
};
