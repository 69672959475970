import { useApolloClient, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';

import addCampaignMutation from './_AddCampaign.gql';
import CampaignForm from './CampaignForm';

export default function CampaignAdd() {
  const apollo = useApolloClient();
  const [totalPercentError, setTotalPercentError] = useState('');

  const [action] = useMutation(addCampaignMutation, {
    onCompleted: async () => {
      // Ensure all lists are refreshed
      await apollo.resetStore();
    },
  });
  const history = useHistory();

  return (
    <>
      <Breadcrumb name="Lägg till ny" />

      <CampaignForm
        initialValues={{
          name: '',
          code: '',
          type: '',
          price: 0,
          limit: '',
          site: '',
          expireDate: '',
          firstPurchaseOnProductOnly: false,
          products: [],
          duration: '',
          endSubscriptionAfterDuration: false,
        }}
        totalPercentError={totalPercentError}
        addCampaign
        onSubmit={async (values, { setSubmitting }) => {
          if (
            values.products.reduce((acc, val) => acc + val.percentage, 0) !==
            100
          ) {
            setTotalPercentError(
              'Den totala procentuella inkomstfördelningen måste vara 100%.',
            );
          } else {
            const { products, duration, expireDate, limit, price, ...rest } =
              values;

            const modifiedValues = {
              ...rest,
              duration: duration === '' ? null : Number.parseInt(duration, 10),
              expireDate: expireDate === '' ? null : expireDate,
              limit: limit === '' ? null : Number.parseInt(limit, 10),
              // Reload products to neglect __typedef
              products: products.map(obj => ({
                id: obj.id,
                name: obj.name,
                percentage: obj.percentage,
              })),
              price: Number(price) * 100,
            };

            const response = await action({
              variables: {
                input: modifiedValues,
              },
            });

            setSubmitting(false);
            history.push(`/campaigns/${response.data.addCampaign.id}`);
          }
        }}
      />
    </>
  );
}
