export const organizations = {
  trmedia_ab: 'TR Media AB',
  jokersystemet_as: 'Jokersystemet AS',
};

export const paymentNetworks = {
  maestro: 'Maestro',
  mastercard: 'Mastercard',
  visa: 'Visa',
};

export const productTypes = {
  book: 'Bok',
  ticket: 'Klippkort',
  subscription: 'Prenumeration',
  license: 'License',
  bettip: 'Speltips',
  bettip_template: 'Speltipsmall',
  single_issue: 'Lösnummer',
  auction: 'Auktion',
};

export const lineItemTypes = {
  product: 'Produkt',
  commerce_coupon: 'Kampanj',
  ticket: 'Klippkort',
  fee: 'Avgift',
  refund: 'Återbetalning',
  shipping: 'Frakt',
  deduction: 'Avdrag',
  credited: 'Krediterad',
  adjustment: 'Justering',
};

export const deliveryMethods = {
  manually: 'Manually',
  mail: 'Mail',
  email: 'Email',
};

export const paymentMethods = {
  card: 'Kreditkort',
  swish: 'Swish',
  invoice: 'Faktura',
  autogiro: 'Autogiro',
  notification: 'Avisering',
  payexInvoice: 'Faktura (swedbankpay)',
  nets: 'NETS', // imported Joker.no orders
  // free denotes if admin has granted a free subscription to the user
  // this is used prevent recurring service to create invoices
  giftCode: 'Värdekod',
  free: 'Gratis',
};

export const paymentIntervals = {
  1: 'Månadsvis',
  3: 'Kvartal',
  6: 'Halvårsvis',
  12: 'Årsvis',
};

export const billectaPaymentMethods = {
  BankGiro: 'BankGiro',
  PlusGiro: 'PlusGiro',
  BankAccount: 'Bankkonto',
};

export const sites = {
  jokersystemet: 'Jokersystemet',
  jokersystemet_no: 'Jokersystemet Norge',
  overodds: 'Överodds',
  spelvarde: 'Spelvärde',
  storavinster: 'Storavinster',
  travfakta: 'Travfakta',
  travronden: 'Travronden',
  travronden_spel: 'Travronden Spel',
  travogat: 'Travögat',
  banprogram: 'Banprogram',
  tr_auktion: 'Trauktion',
};

export const paymentProviders = {
  swedbankpay: 'Swedbank Pay',
  billecta: 'Billecta',
  nets: 'NETS',
  giftCode: 'Värdekod',
  free: 'Gratis',
};

export const subscriptionSites = {
  banprogram: 'Banprogram',
  jokersystemet: 'Jokersystemet',
  jokersystemet_no: 'Jokersystemet NO',
  travfakta: 'Travfakta',
  travronden: 'Travronden',
};

export const domains = {
  jokersystemet: ['https://www.jokersystemet.se', 'https://joker.trdev.se'],
  jokersystemet_no: [
    'https://www.jokersystemet.no',
    'https://jokerno.trdev.se',
  ],
  overodds: ['https://www.overodds.se', 'https://overodds.trdev.se'],
  spelvarde: ['https://www.spelvarde.se', 'https://spelvarde.trdev.se'],
  storavinster: ['https://www.storavinster.se', 'https://test.storavinster.se'],
  travfakta: ['https://www.travfakta.se', 'https://test.travfakta.se'],
  travronden: ['https://www.travronden.se', 'https://travronden.trdev.se'],
  travronden_spel: [
    'https://www.travrondenspel.se',
    'https://travrondenspel.trdev.se',
  ],
  travogat: ['https://www.travogat.se', 'https://test.travogat.se'],
  banprogram: ['https://www.travronden.se', 'https://travronden.trdev.se'],
};

export const dateTypes = {
  daily: 'Dagligen',
  weekly: 'Veckovis',
  monthly: 'Månadsvis',
  yearly: 'Årsvis',
};

export const giftStatus = {
  unused: 'Onyttjad',
  used: 'Nyttjad',
  refunded: 'Återbetalad',
  annulled: 'Avskriven',
};

export const betTypes = [
  'DD',
  'LD',
  'GS75',
  'V4',
  'V64',
  'V65',
  'V75',
  'V86',
  'EXTRAV75',
];

export const watcher = {
  methods: {
    email: 'Via e-post',
    mobile: 'Notis i bevakningsappen',
  },
  when: {
    start_reg: 'Vid startanmälan',
    gamestop: 'Inför spelstopp på spelform',
    race_1: '1 minut innan start',
    race_5: '5 minut innan start',
    race_60: '1 timme innan start',
    race_result: 'Resultat för varje avdelning inom vald spelform',
    result: 'Resultat vid vald spelforms slut',
  },
  sort: {
    name: 'Namn',
    start: 'Startdatum',
    added: 'Tillagd',
  },
  betTypes: ['V75', 'V86', 'GS75', 'V64', 'V65'],
};

export const userWatchTypes = {
  author: 'Skribent',
  horse: 'Häst',
  meet: 'Tävlingsdag',
  start: 'Start',
  topic: 'Ämne',
};

export const userStatus = {
  active: 'Aktiv',
  passive: 'Passiv',
  suspect: 'Suspekt',
  bookkeep: 'Bokföring',
  empty: 'Empty',
};

export const VATs = [0, 6, 25];

export const unSubscribeReasons = {
  expectation: 'Ej enl förväntan',
  limitedTime: 'Inte tid',
  tooExpensive: 'För dyrt',
  passedDueDate: 'Passerat förfallodatumet',
  userBlocked: 'Användaren blockerad',
  autogiroFailed: 'Autogiro misslyckades',
  other: 'Annat',
};

/**
 * @param site used as filter when cancelling subscription of product type/site
 */
export function getUnsubscribeReasons(site) {
  const reasons = { ...unSubscribeReasons };
  // remove system reasons
  delete reasons.passedDueDate;
  delete reasons.userBlocked;
  delete reasons.autogiroFailed;

  switch (site) {
    case 'banprogram':
      reasons.expectation = 'Banprogrammet uppfyller inte mina förväntningar';
      reasons.limitedTime = 'Jag har inte tid att nyttja produkten';
      reasons.tooExpensive = 'Produkten är för dyr';
      break;
    case 'jokersystemet':
      reasons.expectation = 'Jokersystemet uppfyller inte mina förväntningar';
      reasons.limitedTime = 'Jag har inte tid att nyttja produkten';
      reasons.tooExpensive = 'Produkten är för dyr';
      break;
    case 'jokersystemet_no': // Översätt
      reasons.expectation = 'Jokersystemet uppfyller icke mine förväntningar';
      reasons.limitedTime = 'Jag har inte tid att nyttja produkten';
      reasons.tooExpensive = 'Produkten är för dyr';
      break;
    case 'overodds':
      reasons.expectation = 'Överodds uppfyller inte mina förväntningar';
      reasons.limitedTime = 'Jag har inte tid att nyttja produkten';
      reasons.tooExpensive = 'Produkten är för dyr';
      break;
    case 'spelvarde':
      reasons.expectation = 'Spelvärde uppfyller inte mina förväntningar';
      reasons.limitedTime = 'Jag har inte tid att nyttja produkten';
      reasons.tooExpensive = 'Produkten är för dyr';
      break;
    case 'storavinster':
      reasons.expectation = 'Storavinster uppfyller inte mina förväntningar';
      reasons.limitedTime = 'Jag har inte tid att nyttja produkten';
      reasons.tooExpensive = 'Produkten är för dyr';
      break;
    case 'travfakta':
      reasons.expectation = 'Travfakta uppfyller inte mina förväntningar';
      reasons.limitedTime = 'Jag har inte tid att nyttja produkten';
      reasons.tooExpensive = 'Produkten är för dyr';
      break;
    case 'travronden':
      reasons.expectation = 'Travronden uppfyller inte mina förväntningar';
      reasons.limitedTime = 'Jag har inte tid att nyttja min prenumeration';
      reasons.tooExpensive = 'Prenumerationen är för dyr';
      break;
    case 'travronden_spel':
      reasons.expectation = 'Travronden Spel uppfyller inte mina förväntningar';
      reasons.limitedTime = 'Jag har inte tid att nyttja min prenumeration';
      reasons.tooExpensive = 'Prenumerationen är för dyr';
      break;
    case 'travogat':
      reasons.expectation = 'Travögat uppfyller inte mina förväntningar';
      reasons.limitedTime = 'Jag har inte tid att nyttja produkten';
      reasons.tooExpensive = 'Produkten är för dyr';
      break;
    default:
      reasons.expectation = 'Produkten uppfyller inte mina förväntningar';
      reasons.limitedTime = 'Jag har inte tid att nyttja produkten';
      reasons.tooExpensive = 'Produkten är för dyr';
      break;
  }
  return Object.entries(reasons).map(([key, value]) => ({
    reason: key,
    text: value,
  }));
}

export const transactionStatus = {
  pending: 'Inväntar',
  complete: 'Slutförd',
  canceled: 'Avbruten',
  failed: 'Misslyckad',
  hard_decline: 'Ogiltigt kort',
};

export const distributableProducts = {
  'MC-TRT': 'Travronden',
  'MC-V75': 'Banprogram V75',
  'MC-V86': 'Banprogram V86',
  'MC-TVI': 'Vinternumret',
  'MC-EIN': 'Einars',
};

export const orderBuilderStatus = {
  draft: 'Utkast',
  awaiting: 'Klar att skickas',
  in_progress: 'Pågående',
  complete: 'Slutförd',
  closed: 'Stängd',
};

export const orderStatus = {
  draft: 'Utkast',
  complete: 'Slutförd',
  closed: 'Stängd',
};

export const deviationStatus = {
  resolved_cancelled: 'Bortbokad',
  resolved_refunded: 'Återbetalad',
  resolved_cleared: 'Kvittad mot betalning',
  unresolved_unhandled: 'Ohanterad',
  unresolved_subscription_started: 'Prenumeration startad',
  unresolved_reminder_sent: 'Påminnelse skickad',
};

export const paymentStatus = {
  init: 'Initierad',
  awaiting: 'Inväntar betalning',
  reserved: 'Reserverad (ej captured)',
  paid: 'Betald',
  failed: 'Misslyckad',
  credited: 'Krediterad',
};

export const complaintCodes = {
  1: 'Tidningen kom inte',
  2: 'Tidningen var skadad',
  //3: 'Meddelande (ej reklamation)',
  4: 'Tidningen kom sent',
  5: 'Tidningen var ej nedstoppad i brevlådan',
  //6: 'Abonnemang saknas, dela ej ut tidningen',
  7: 'Fel uppgifter på tidningen',
};

// Additional complaintCodes for damaged magazine (complaintCode: 2)
export const complaintCodesByDamaged = {
  21: 'Våt',
  22: 'Trasig',
  23: 'Läst',
  24: 'Bilaga saknas',
};

// VAT search: https://ec.europa.eu/taxation_customs/tedb/taxSearch.html
export const countriesEU = {
  AT: {
    country: 'Österrike',
    standardVAT: 20,
    reducedVAT: 10,
    currency: 'EUR',
  },
  AX: { country: 'Åland', standardVAT: 24, reducedVAT: 10, currency: 'EUR' },
  BE: { country: 'Belgien', standardVAT: 21, reducedVAT: 6, currency: 'EUR' },
  BG: {
    country: 'Bulgarien',
    standardVAT: 20,
    reducedVAT: 9,
    currency: 'BGN',
  },
  CY: { country: 'Cypern', standardVAT: 19, reducedVAT: 5, currency: 'EUR' },
  CZ: {
    country: 'Tjeckien',
    standardVAT: 21,
    reducedVAT: 10,
    currency: 'CZK',
  },
  DE: {
    country: 'Tyskland',
    standardVAT: 19,
    reducedVAT: 7,
    currency: 'EUR',
  },
  DK: {
    country: 'Danmark',
    standardVAT: 25,
    reducedVAT: 25,
    currency: 'DKK',
  },
  EE: { country: 'Estland', standardVAT: 20, reducedVAT: 9, currency: 'EUR' },
  ES: { country: 'Spanien', standardVAT: 21, reducedVAT: 4, currency: 'EUR' },
  FI: {
    country: 'Finland',
    standardVAT: 24,
    reducedVAT: 10,
    currency: 'EUR',
  },
  FR: {
    country: 'Frankrike',
    standardVAT: 20,
    reducedVAT: 5.5,
    currency: 'EUR',
  },
  GR: {
    country: 'Grekland',
    standardVAT: 24,
    reducedVAT: 6,
    currency: 'EUR',
  },
  HR: {
    country: 'Kroatien',
    standardVAT: 25,
    reducedVAT: 5,
    currency: 'HRK',
  },
  HU: { country: 'Ungern', standardVAT: 27, reducedVAT: 5, currency: 'HUF' },
  IE: { country: 'Irland', standardVAT: 23, reducedVAT: 9, currency: 'EUR' },
  IT: { country: 'Italien', standardVAT: 22, reducedVAT: 4, currency: 'EUR' },
  LU: {
    country: 'Luxemburg',
    standardVAT: 17,
    reducedVAT: 3,
    currency: 'EUR',
  },
  LT: { country: 'Litauen', standardVAT: 21, reducedVAT: 9, currency: 'EUR' },
  LV: {
    country: 'Lettland',
    standardVAT: 21,
    reducedVAT: 5,
    currency: 'EUR',
  },
  MT: { country: 'Malta', standardVAT: 18, reducedVAT: 5, currency: 'EUR' },
  NL: {
    country: 'Nederländerna',
    standardVAT: 21,
    reducedVAT: 9,
    currency: 'EUR',
  },
  PL: { country: 'Polen', standardVAT: 23, reducedVAT: 8, currency: 'PLN' },
  PT: {
    country: 'Portugal',
    standardVAT: 23,
    reducedVAT: 6,
    currency: 'EUR',
  },
  RO: {
    country: 'Rumänien',
    standardVAT: 19,
    reducedVAT: 5,
    currency: 'RON',
  },
  SI: {
    country: 'Slovenien',
    standardVAT: 22,
    reducedVAT: 5,
    currency: 'EUR',
  },
  SK: {
    country: 'Slovakien',
    standardVAT: 20,
    reducedVAT: 10,
    currency: 'EUR',
  },
};

export const userRoles = {
  admin: 'Admin',
  attestant: 'Attestant',
  'andelsspel:admin': 'Andelsspel: Admin',
  'andelsspel:contributor': 'Andelsspel: Skribent',
  'andelsspel:editor': 'Andelsspel: Redaktör',
  'banprogram:distributor': 'Banprogram: Distributör',
  'breedly:admin': 'Breedly: Admin',
  'customer-service': 'Kundtjänst',
  'external_bet25:admin': 'Bet 25: Admin',
  'external_bet25:editor': 'Bet 25: Redaktör',
  'external_bet25:contributor': 'Bet 25: Skribent',
  'external_atg:admin': 'ATG: Admin',
  'external_atg:editor': 'ATG: Redaktör',
  'external_atg:contributor': 'ATG: Skribent',
  'external_atg_international:admin': 'ATG Internationellt: Admin',
  'external_atg_international:editor': 'ATG Internationellt: Redaktör',
  'external_atg_international:contributor': 'ATG Internationellt: Skribent',
  'external_rikstoto:admin': 'Rikstoto: Admin',
  'external_rikstoto:editor': 'Rikstoto: Redaktör',
  'external_rikstoto:contributor': 'Rikstoto: Skribent',
  'external_tr_spel_b2b:admin': 'TR Spel B2B: Admin',
  'external_tr_spel_b2b:editor': 'TR Spel B2B: Redaktör',
  'external_tr_spel_b2b:contributor': 'TR Spel B2B: Skribent',
  'galopptips:admin': 'Galopptips: Admin',
  'galopptips:contributor': 'Galopptips: Skribent',
  'galopptips:editor': 'Galopptips: Redaktör',
  'joker_sport:admin': 'Joker Sport: Admin',
  'joker_sport:contributor': 'Joker Sport: Skribent',
  'joker_sport:editor': 'Joker Sport: Redaktör',
  'jokersystemet:admin': 'Jokersystemet: Admin',
  'jokersystemet:contributor': 'Jokersystemet: Skribent',
  'jokersystemet:editor': 'Jokersystemet: Redaktör',
  'jokersystemet:freerole-premium': 'Jokersystemet: Gratis premium',
  'jokersystemet:freerole': 'Jokersystemet: Gratis',
  'jokersystemet_no:admin': 'Jokersystemet NO: Admin',
  'jokersystemet_no:contributor': 'Jokersystemet NO: Skribent',
  'jokersystemet_no:editor': 'Jokersystemet NO: Redaktör',
  'overodds:admin': 'Överodds: Admin',
  'overodds:contributor': 'Överodds: Skribent',
  'overodds:editor': 'Överodds: Redaktör',
  'spelvarde:admin': 'Spelvärde: Admin',
  'spelvarde:editor': 'Spelvärde: Editor',
  'spelvarde:freerole': 'Spelvärde: Gratis',
  'spelvarde:tipster': 'Spelvärde: Tipster',
  'storavinster:admin': 'Storavinster: Admin',
  'storavinster:editor': 'Storavinster: Editor',
  'storavinster:freerole': 'Storavinster: Gratis',
  'storavinster:tipster': 'Storavinster: Tipster',
  'trais:admin': 'TRAIS: Admin',
  'trais:contributor': 'TRAIS: Skribent',
  'trais:editor': 'TRAIS: Redaktör',
  'travfakta:admin': 'Travfakta: Admin',
  'travfakta:editor': 'Travfakta: Editor',
  'travfakta:freerole-gold': 'Travfakta: Gratis guld', //TODO: To be removed after launch of new travfakta
  'travfakta:freerole-silver': 'Travfakta: Gratis silver', //TODO: To be removed after launch of new travfakta
  'travfakta:freerole-bronze': 'Travfakta: Gratis brons', //TODO: To be removed after launch of new travfakta
  'travfakta:freerole-full': 'Travfakta: Gratis full',
  'travfakta:freerole-standard': 'Travfakta: Gratis standard',
  'travfakta:freerole-premium': 'Travfakta: Gratis premium',
  'travfakta:startlist-ignore-delay': 'Travfakta: Startlist ignore delay',
  'travogat:admin': 'Travögat: Admin',
  'travogat:editor': 'Travögat: Editor',
  'travogat:freerole': 'Travögat: Gratis',
  'travogat:tipster': 'Travögat: Tipster',
  'travronden_spel:admin': 'Travronden Spel: Admin',
  'travronden_spel:contributor': 'Travronden Spel: Skribent',
  'travronden_spel:editor': 'Travronden Spel: Redaktör',
  'travronden_spel:freerole': 'Travronden Spel: Gratis',
  'travronden:admin': 'Travronden: Admin',
  'travronden:contributor': 'Travronden: Skribent',
  'travronden:editor': 'Travronden: Redaktör',
  'travronden:freerole': 'Travronden: Gratis',
  'trhub:admin': 'TR-hub: Admin',
  'trhub:contributor': 'TR-hub: Skribent',
  'trhub:editor': 'TR-hub: Redaktör',
  'trmedia:admin': 'TR Media: Admin',
  'trmedia:contributor': 'TR Media: Skribent',
  'trmedia:editor': 'TR Media: Redaktör',
  'trmedia:start-commentator': 'TR Media: Startkommentator',
};

export const accessMap = {
  getUser: ['admin', 'customer-service'],
  getUsers: ['admin', 'customer-service'],
  addUser: ['admin', 'customer-service'],
  editUser: ['admin', 'customer-service'],
  blockUser: ['admin', 'customer-service'],
  getUserWatchings: ['admin', 'customer-service'],
  loginAsUserLink: ['trhub:login-as-user'],
  resetPassword: ['admin', 'customer-service'],
  resetTwoFactor: ['admin'],
  removeUser: ['admin', 'customer-service'],
  getTicketByUser: ['admin', 'customer-service'],
  getTicketsByUser: ['admin', 'customer-service'],
  getSubscriptionsByUser: ['admin', 'customer-service'],
  getDistributionsByUser: ['admin', 'customer-service'],
  getAccessesByUser: ['admin', 'customer-service'],
  getProducts: ['admin', 'customer-service'],
  addProduct: ['admin'],
  editProduct: ['admin'],
  uploadProductPdf: ['admin'],
  removeProductPdf: ['admin'],
  uploadOrderBuilderFile: ['admin'],
  removeOrderBuilderFile: ['admin'],
  giveAccessToUser: ['admin', 'customer-service'],
  getCampaigns: ['admin', 'customer-service'],
  addCampaign: ['admin'],
  editCampaign: ['admin'],
  getActiveCampaigns: ['admin'],
  getCampaignSettings: ['admin'],
  getCampaignLandingPages: ['admin', 'customer-service'],
  editCampaignSettings: ['admin'],
  deleteCampaign: ['admin'],
  getDistributions: ['admin', 'customer-service', 'banprogram:distributor'],
  generateDistributions: ['admin', 'banprogram:distributor'],
  exportDistribution: ['admin', 'banprogram:distributor'],
  handleDistributionComplaint: ['admin', 'customer-service'],
  getEditions: ['admin', 'customer-service', 'banprogram:distributor'],
  addOrderBuilder: ['admin'],
  editOrderBuilder: ['admin'],
  sendOrderBuilder: ['admin'],
  getOrderBuilder: ['admin', 'customer-service'],
  getOrderBuilders: ['admin', 'customer-service'],
  removeOrderBuilder: ['admin', 'customer-service'],
  failedOrdersReport: ['admin', 'customer-service'],
  addEdition: ['admin'],
  editEdition: ['admin'],
  handleContactMessage: ['admin'],
  getIssue: ['admin'],
  getIssues: ['admin'],
  addIssue: ['admin'],
  editIssue: ['admin'],
  closeIssue: ['admin'],
  removeIssue: ['admin'],
  // getMarketReportDateRange: ['admin'],
  getGraphKeyMetrics: ['admin'],
  exportGraphKeyMetrics: ['admin'],
  exportSubscriptions: ['admin'],
  exportCanceledSubscriptions: ['admin', 'customer-service'],
  exportGiftDebt: ['admin'],
  exportSubscriptionsExpiring: ['admin'],
  getSubscriptionStats: ['admin'],
  exportSubscriptionStats: ['admin'],
  getOrder: ['admin', 'customer-service'],
  getOrders: ['admin', 'customer-service'],
  getUnusedGiftCodePurchaseOrders: ['admin', 'customer-service'],
  getUsedGiftCodePurchaseOrders: ['admin', 'customer-service'],
  editOrder: ['admin'],
  approveDeviation: ['admin'],
  clearDeviation: ['admin'],
  sendDeviationReminder: ['admin', 'customer-service'],
  previewInvoice: ['admin'],
  previewNotification: ['admin', 'customer-service'],
  previewOrderBuilder: ['admin', 'customer-service'],
  createUserOrder: ['admin', 'customer-service'],
  createCollectionPayeeOrder: ['admin'],
  addRefund: ['admin', 'customer-service'],
  removeRefund: ['admin'],
  attestRefund: ['attestant'],
  exportRefunds: ['admin'],
  getSalesReports: ['admin'],
  exportSalesReports: ['admin'],
  getDeviationReport: ['admin'],
  exportDeviationReport: ['admin'],
  getSubscriptionExpiryReports: ['admin'],
  getEconomySalesReport: ['admin'],
  exportEconomySalesReport: ['admin'],
  exportEconomySalesOrdersReport: ['admin'],
  exportEconomySAFTReport: ['admin'],
  getDebtReports: ['admin'],
  exportDebtReports: ['admin'],
  exportDebtOrdersReport: ['admin'],
  getMossReport: ['admin'],
  exportMossReports: ['admin'],
  getSubscription: ['admin', 'customer-service'],
  getSubscriptions: ['admin', 'customer-service'],
  getSubscriptionsProducts: ['admin', 'customer-service'],
  pauseSubscription: ['admin', 'customer-service'],
  extendSubscription: ['admin', 'customer-service'],
  changeDeliveryMethodSubscription: ['admin', 'customer-service'],
  cancelSubscription: ['admin', 'customer-service'],
  reactivateSubscription: ['admin', 'customer-service'],
  giveSubscriptionToUser: ['admin', 'customer-service'],
  addSubscriptionToPayee: ['admin', 'customer-service'],
  getTerms: ['admin'],
  getTermsList: ['admin'],
  addTerms: ['admin'],
  editTerms: ['admin'],
  removeTerms: ['admin'],
  addTicket: ['admin'],
  editTicket: ['admin'],
  giveTicketToUser: ['admin'],
  getUnsubscriptionReports: ['admin'],
  getComplaintReport: ['admin'],
  addLogMessage: ['admin', 'customer-service'],
  removeLogMessage: ['admin', 'customer-service'],
  getCards: ['admin', 'customer-service'],
  getCardPaymentSuccessReport: ['admin'],
  getOauthClients: ['admin'],
  saveOauthClient: ['admin'],
  deleteOauthClient: ['admin'],
};

export const campaignTypes = {
  fixedPrice: 'Fast pris',
  monthlyDiscount: 'Avdrag per månad',
};

export const issueStatus = {
  OPEN: 'Öppen',
  ACKNOWLEDGED: 'Pågående',
  CLOSED: 'Stängd',
};

/**
 * Projects are used by the economy department to assign products to different accounts.
 * It is needed among products that is to be synced to Billecta.
 */
export const allProjects = [
  100, 101, 102, 111, 200, 202, 206, 207, 208, 209, 300, 301, 302, 304, 305,
  306, 307, 308, 309, 900, 901, 902, 903, 904,
];

/**
 * costCenters are used by the economy department to assign products to different accounts.
 * It is needed among products that is to be synced to Billecta.
 */
export const allCostCenters = [10, 20, 30, 90];

/**
 * user roles which grants access to prenly-products
 */
export const prenlyAccessRoles = {
  'travronden:freerole': ['TR-SPORT-ETIDNING'],
  'travronden_spel:freerole': ['TR-SPEL-ETIDNING', 'TR-HELG-ETIDNING'],
};

/**
 * SKUs of products which grants access to prenly-products
 */
export const prenlySKUs = [
  'TR-SPORT-ETIDNING',
  'TR-SPEL-ETIDNING',
  'TR-HELG-ETIDNING',
  'TR-MAGASIN',
];

/**
 * SKUs of yearly subscriptions that will always end after a year
 */
export const oneYearSubSKUs = [
  'JOK-TICKET-1439',
  'JOK-TICKET-6065',
  'ST-TICKET-1905',
  'ST-V86-YEARLY',
  'SV-TICKET-137',
  'SV-TICKET-138',
  'TA-TICKET-1460',
  'TA-TICKET-1463',
  'TA-TICKET-1466',
  'TA-TICKET-2455',
  'TR-TICKET-2171',
  'TR-TICKET-5211',
  'TF-SUB-GOLD-YEAR',
  'TF-SUB-SILVER-YEAR',
  'TF-SUB-BRONZE-YEAR',
];

/**
 * SKUs of products which can be used to create orders directly from admin
 */
export const createOrderFromAdminProducts = [
  'TR-SPORT-SPEL-DIGITAL-YEARLY',
  'TR-SPORT-SPEL-PRINT-YEARLY',
  'TR-SPORT-SPEL-PRINT-QUARTERLY',
  'MC-V75-YEARLY',
];

/**
 * SKUs of products which can be used for orderBuilders
 */
export const orderBuilderProducts = [
  'TR-SPORT-SPEL-PRINT-YEARLY',
  'TR-SPORT-SPEL-PRINT-QUARTERLY',
];

export const cardOrigins = {
  checkout: 'Checkout',
  drupal: 'Drupal',
};

export const auctionStatus = {
  preliminary: 'Preliminär',
  completed: 'Slutförd',
  cancelled: 'Avbruten',
};

export const auctionTypes = {
  auction: 'Auction',
  fixed: 'Fast pris',
};

export const auctionParticipationRole = {
  buyer: 'Köpare',
  seller: 'Säljare',
  bidder: 'Budgivare',
};

export const termsStatus = {
  draft: 'Utkast',
  published: 'Publicerad',
  unpublished: 'Avpublicerad',
};

// Oldest order year, for dynamic year filters
export const orderSearchStartYear = 2013;

// -- OAUTH LEGACY --
export const sitesMap = new Map(
  Object.entries({
    jokersystemet_no: /(jokersystemet\.no)|(jokerno)|(jokersystemet-client-no)/,
    jokersystemet: /(jokersystemet\.se)|(joker\.)|(jokersystemet-client)/,
    travronden_spel: /travronden-?spel/,
    travronden: /(travronden([.-]))|(localhost:7002)/,
    travfakta: /travfakta/,
    storavinster: /storavinster/,
    overodds: /overodds/,
    travogat: /travogat/,
    spelvarde: /spelvarde/,
    trcontent: /content/,
    local: /localhost/,
  }),
);
