import { useApolloClient, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { addWeeks, differenceInDays, startOfDay } from 'date-fns';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { Breadcrumb } from '~/components/Breadcrumbs';
import DateInputField from '~/components/DateInputField';
import { subscriptionPropType } from '~/propTypes';

import mutation from './_PauseSubscription.gql';

const minPauseWeeks = 2;
const minPauseWeeksText = 'två';
const maxPauseWeeks = 8;
const maxPauseWeeksText = 'åtta';

yup.addMethod(yup.date, 'inValidDateRange', function (errorMessage) {
  return this.test('inRange', errorMessage, function (value) {
    const { path, createError, parent } = this;

    const startDate = parent.pauseStartDate;

    if (startDate == null) {
      return value;
    }

    if (differenceInDays(value, startDate) < 0) {
      return createError({
        path,
        message: 'Slutdatumet är innan startdatumet',
      });
    }

    if (differenceInDays(value, startDate) < minPauseWeeks * 7) {
      return createError({
        path,
        message: `Ett uppehåll måste vara i minst ${minPauseWeeksText} veckor`,
      });
    }

    if (differenceInDays(value, startDate) > maxPauseWeeks * 7) {
      return createError({
        path,
        message: `Uppehåll kan inte göras längre än i ${maxPauseWeeksText} veckor`,
      });
    }

    return value;
  });
});

const validationSchema = yup.object().shape({
  pauseStartDate: yup
    .date()
    .required('Ange startdatum')
    .min(
      startOfDay(new Date()),
      'Startdatum får tidigast vara innevarande dag',
    ),
  pauseEndDate: yup.date().required('Ange slutdatum').inValidDateRange(),
});

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
    marginBottom: theme.spacing(3),
  },
}));

export default function SubscriptionPause({ subscriptionData }) {
  const classes = useStyles();

  const apollo = useApolloClient();

  const history = useHistory();

  const { subscription } = useParams();

  const { pauseStartDate, pauseEndDate, user } =
    subscriptionData.getSubscription;

  const [action] = useMutation(mutation, {
    onCompleted: async () => {
      // Ensure all lists are refreshed
      await apollo.resetStore();
    },
  });

  return (
    <>
      <Breadcrumb name="Pausa prenumeration" />
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h5" component="h2">
            Pausa en prenumeration
          </Typography>
          <Typography
            variant="body2"
            component="p"
            sx={theme => ({
              marginTop: theme.spacing(2),
            })}
          >
            Uppehållet innebär endast att den fysiska papperstidningen inte
            skickas inom valt datumintervall. Access till e-tidningar och sajter
            ska inte påverkas som produktgruppen berör, och det görs ingen
            justering i pris eller prenumerationslängd.
          </Typography>
          <Typography
            variant="body2"
            component="p"
            sx={theme => ({
              marginTop: theme.spacing(2),
            })}
          >
            Uppehåll sker i minst två veckor och i max två månader. Det går inte
            att avbryta uppehållet.
          </Typography>

          <Typography
            variant="body2"
            component="p"
            sx={theme => ({
              marginTop: theme.spacing(2),
            })}
          >
            Kunden får inte skapa en ny pausperiod om det redan finns en för
            samma år.
          </Typography>
        </CardContent>
      </Card>

      <Formik
        initialValues={{
          pauseStartDate: pauseStartDate || '',
          pauseEndDate: pauseEndDate || '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          const response = await action({
            variables: {
              id: subscription,
              user: user.id,
              ...values,
            },
          });
          setSubmitting(false);

          if (response) {
            history.push(`/subscriptions/${subscription}`);
          }
        }}
        validateOnBlur={false}
      >
        {({ submitForm, isSubmitting, setFieldValue, values }) => (
          <Form
            css={({ theme }) => ({
              '> * + *': {
                marginTop: theme.spacing(2),
              },
              '> *': {
                width: '100%',
              },
              '> .MuiFormControl-root': {
                backgroundColor: theme.palette.background.default,
              },
            })}
          >
            <DateInputField
              setFieldValue={setFieldValue}
              name="pauseStartDate"
              label="Uppehåll från"
              minDate={new Date()}
              clearIconEnabled={false}
              value={values.pauseStartDate}
              required
            />
            <DateInputField
              setFieldValue={setFieldValue}
              name="pauseEndDate"
              label="Slutdatum"
              minDate={addWeeks(new Date(), minPauseWeeks)}
              value={values.pauseEndDate}
              required
            />
            {isSubmitting && <LinearProgress />}
            <Box>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  isSubmitting ||
                  new Date(pauseStartDate).getFullYear() ===
                    new Date(values.pauseStartDate).getFullYear()
                }
                onClick={submitForm}
              >
                Spara
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
}

SubscriptionPause.propTypes = {
  subscriptionData: PropTypes.shape({
    getSubscription: subscriptionPropType,
  }).isRequired,
};
