import {
  Box,
  Button,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { Link as RouterLink, NavLink, useLocation } from 'react-router-dom';
import { deliveryMethods, paymentMethods } from 'trhub-utils';

import DebtList from '~/components/DebtList';
import Events from '~/components/Events';
import OrderList from '~/components/OrderList';
import TablePaper from '~/components/TablePaper';
import { subscriptionPropType } from '~/propTypes';

export default function SubscriptionDetail({ subscriptionData }) {
  const { pathname } = useLocation();

  const {
    id,
    campaign,
    user,
    payee,
    product,
    paymentMethod,
    startDate,
    dueDate,
    debt,
    unsubscribeDate,
    endDate,
    pauseStartDate,
    pauseEndDate,
    isDistributable,
    latestRenewalAttempt,
    latestSuccessfulRenewal,
    lastCampaignDate,
    deliveryMethod,
    drupalId,
    eventLog,
  } = subscriptionData.getSubscription;

  const tableRows = [
    [
      'Prenumerant',
      <Link key={user.id} component={RouterLink} to={`/users/${user.id}`}>
        {user.label}
      </Link>,
    ],
    [
      'Produkt',
      <Link
        key={product.id}
        component={RouterLink}
        to={`/products/${product.id}`}
      >
        {product.name}
      </Link>,
    ],
    ['Betalningsmetod', paymentMethods[paymentMethod]],
    ['Startdatum', format(new Date(startDate), 'yyyy-MM-dd HH:mm')],
    [
      'Förfallodag',
      dueDate ? format(new Date(dueDate), 'yyyy-MM-dd HH:mm') : '',
    ],
    [
      'Uppsägningsdatum',
      unsubscribeDate
        ? format(new Date(unsubscribeDate), 'yyyy-MM-dd HH:mm')
        : '',
    ],
    ['Slutdatum', endDate ? format(new Date(endDate), 'yyyy-MM-dd HH:mm') : ''],
    [
      'Senaste förnyelse',
      latestSuccessfulRenewal
        ? format(new Date(latestSuccessfulRenewal), 'yyyy-MM-dd HH:mm')
        : '',
    ],
    [
      'Senaste förnyelseförsök',
      latestRenewalAttempt
        ? format(new Date(latestRenewalAttempt), 'yyyy-MM-dd HH:mm')
        : '',
    ],
    [
      'Kampanj',
      campaign ? (
        <Link
          key={campaign.id}
          component={RouterLink}
          to={`/campaigns/${campaign.id}`}
        >
          {campaign.name}
        </Link>
      ) : (
        ''
      ),
    ],
  ];

  if (lastCampaignDate) {
    tableRows.push([
      'Sista datum som kampanj gäller för prenumerationen',
      lastCampaignDate
        ? format(new Date(lastCampaignDate), 'yyyy-MM-dd HH:mm')
        : '',
    ]);
  }

  if (drupalId) {
    tableRows.push(['Drupal ID', drupalId]);
  }

  if (deliveryMethod) {
    tableRows.push([
      'Leveransmetod',
      deliveryMethod === 'email' && paymentMethod === 'notification'
        ? `${
            deliveryMethods[deliveryMethod] && isDistributable
          } (Fysisk avi skickas ut 15 dagar innan förfallodatumet vid oläst e-postmeddelande)`
        : deliveryMethods[deliveryMethod],
    ]);
  }

  // insert distinct payee after user in the table
  user.id !== payee.id &&
    tableRows.splice(1, 0, [
      'Betalningsmottagare',
      <Link key={payee.id} component={RouterLink} to={`/users/${payee.id}`}>
        {payee.label}
      </Link>,
    ]);

  pauseStartDate &&
    tableRows.push([
      'Uppehåll från',
      format(new Date(pauseStartDate), 'yyyy-MM-dd'),
    ]);
  pauseEndDate &&
    tableRows.push([
      'Uppehåll till',
      format(new Date(pauseEndDate), 'yyyy-MM-dd'),
    ]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <TablePaper>
          <TableContainer>
            <Table>
              <TableBody>
                {tableRows.map(([label, value]) => (
                  <TableRow key={label}>
                    <TableCell component="th" scope="row">
                      {label}
                    </TableCell>
                    <TableCell align="right">{value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TablePaper>
        <Box mt={2}>
          <Button
            component={NavLink}
            to={`${pathname}/change-delivery-method`}
            variant="contained"
            color="secondary"
          >
            Ändra leveransmetod
          </Button>
        </Box>
        <Box mt={2}>
          <Button
            component={NavLink}
            to={`${pathname}/extend`}
            variant="contained"
            color="secondary"
          >
            Förläng förfallodag
          </Button>
        </Box>
        <Box mt={2}>
          <Button
            component={NavLink}
            to={`${pathname}/cancel`}
            variant="contained"
            color="secondary"
          >
            Avsluta prenumeration
          </Button>
        </Box>
        {endDate && (
          <Box mt={2}>
            <Button
              component={NavLink}
              to={`${pathname}/reactivate`}
              variant="contained"
              color="secondary"
            >
              Återuppta prenumeration
            </Button>
          </Box>
        )}
        {product.paymentInterval === 12 && isDistributable && (
          <Box mt={2}>
            <Button
              component={NavLink}
              to={`${pathname}/pause`}
              variant="contained"
              color="secondary"
            >
              Pausa prenumeration
            </Button>
          </Box>
        )}
      </Grid>

      <Grid item xs={12} md={6}>
        <Events items={eventLog} />
      </Grid>

      <Grid item xs={12} md={12}>
        <OrderList filter={{ subscription: id }} />
      </Grid>

      <Grid item xs={12} md={12}>
        <DebtList debtList={debt} />
      </Grid>
    </Grid>
  );
}

SubscriptionDetail.propTypes = {
  subscriptionData: PropTypes.shape({
    getSubscription: subscriptionPropType,
  }).isRequired,
};
